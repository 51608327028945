import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Col,
  Row,
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Alert,
} from "react-bootstrap";
import { AlignLeft, AlignCenter, AlignRight } from "react-feather";
import { useLocation, useSearchParams } from "react-router-dom";

import "./ProductVariant.css";

import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";
import { fabric } from "fabric";

import WithLoading from "../../components/WithLoading";
import ImagePreview from "../../components/ImagePreview";
import { Eye } from "react-feather";
import { Sticky, StickyContainer } from "react-sticky";

import annotations from "./annotations";

import NotyfContext from "../../contexts/NotyfContext";

import {
  getProductVariants,
  getTemplates,
} from "../../services/productVariant";
import { toBase64 } from "../../utils/toBase64";
import { addImage } from "../../utils/canvas";

const alignments = [
  { value: "left", Icon: AlignLeft },
  { value: "center", Icon: AlignCenter },
  { value: "right", Icon: AlignRight },
];

const keyActionsMap = {
  ArrowRight: { x: 1, y: 0 },
  ArrowDown: { x: 0, y: 1 },
  ArrowLeft: { x: -1, y: 0 },
  ArrowUp: { x: 0, y: -1 },
  // Backspace: { remove: true },
  // Delete: { remove: true },
};

const deleteIcon =
  "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAAoklEQVRYhe2WMQrDMAxFX3qHmvb+R8kWOiXQQHoYZ1GgQ2NsWXY66K1G+g+MZIPjOH/OCLyAoKgNUjvWCExABJZCiSA1UXqouQOzNFqBp6LmUSNQKmEeXiLRLDxHonl4SqJb+C+JDXj3DP+WOIIj8NGG3wylBsNeSVJXkLMnzMKPO9csK7PwszNziZxRayZRMufmEpolYypx+XN8+YfEcZzm7McLWPPS8dTFAAAAAElFTkSuQmCC";

const deleteImg = document.createElement("img");
deleteImg.src = deleteIcon;

function deleteObject(_eventData, transform) {
  const target = transform.target;
  const canvas = target.canvas;
  canvas.remove(target);
  canvas.requestRenderAll();
}

const renderIcon = (icon) =>
  function renderIcon(ctx, left, top, _styleOverride, fabricObject) {
    const size = this.cornerSize;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(icon, -size / 2, -size / 2, size, size);
    ctx.restore();
  };

const deleteControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -16,
  offsetX: 16,
  cursorStyle: "pointer",
  mouseUpHandler: deleteObject,
  render: renderIcon(deleteImg),
  cornerSize: 18,
});

fabric.Object.prototype.controls.deleteControl = deleteControl;
if (fabric.Textbox) {
  fabric.Textbox.prototype.controls.deleteControl = deleteControl;
}

const ProductVariant = ({ isTemplate = false }) => {
  let form = new FormData();

  const { editor, onReady } = useFabricJSEditor();
  const [query] = useSearchParams();
  const location = useLocation();
  const variantId = query.get("variantId");
  const [variant, setVariant] = useState();
  const [messageType, setMessageType] = useState(0);
  const [message, setMessage] = useState('');
  const [data, setData] = useState([
    ...annotations.map((x) => ({
      ...x,
      fontSize: 16,
      value: x.value || x.label,
      textAlign: x.textAlign || "left",
    })),
  ]);
  const [showActualValues, setShowActualValues] = useState(false);
  //  const [fontSizeState, setFontSizeState] = useState(annotations.map(x => ({ id: x.id, fontSize: 16 })));
  const [backgrounds, setBackgrounds] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [fromTemplate, setFromTemplate] = useState(false);

  const [backFile, setBackFile] = useState();
  const [frontFile, setFrontFile] = useState();
  const [loading, setLoading] = useState(true);
  const [inited, setInited] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const notify = useContext(NotyfContext);
  const testRef = useRef(editor);
  // refs are availabe in events, states are not
  testRef.current = editor;

  useEffect(async () => {
    let response = await fetch(
      "https://api.abccheck.webarysites.com/api/Backgrounds",
      {
        method: "GET",
      }
    );

    if (response.ok) {
      let json = await response.json();
      setBackgrounds(json);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    /** @param {KeyboardEvent} event */
    const listener = (event) => {
      const object = testRef.current.canvas.getActiveObject();
      const action = keyActionsMap[event.key];
      if (!object || event.ctrlKey || !action) {
        return;
      }
      const multiplier = event.metaKey ? 100 : event.altKey ? 10 : 1;
      object.left += action.x * multiplier;
      object.top += action.y * multiplier;
      if (action.remove) {
        testRef.current.canvas.remove(object);
      }
      event.preventDefault();
      testRef.current.canvas.renderAll();
    };

    document.addEventListener("keydown", listener);
    return () => document.removeEventListener("keypress", listener);
  }, []);

  useEffect(() => {
    const wpProductId = query.get("WPProductId");
    if (!isTemplate) {
      getTemplates().then((res) => {
        setTemplates(res);
      });
    }
    if (!variantId && !wpProductId) {
      setLoading(false);
      return;
    }

    if (location.state) {
      setVariant({
        ...location.state,
        backgrounds: JSON.parse(location.state.backgrounds),
      });
      window.history.replaceState({}, document.title);
      setLoading(false);
      return;
    }

    (isTemplate ? getTemplates() : getProductVariants(wpProductId))
      .then((res) => {
        if (wpProductId && !isTemplate) {
          if (res.backgrounds && typeof res.backgrounds === "string") {
            res.backgrounds = JSON.parse(res.backgrounds);
          }
          setVariant(res);
          return;
        }
        const variant = res.find((item) => item.id === +variantId);
        if (variant.backgrounds && typeof variant.backgrounds === "string") {
          variant.backgrounds = JSON.parse(variant.backgrounds);
        }
        setVariant(variant);
      })
      .finally(() => setLoading(false));
  }, [variantId, isTemplate]);

  useEffect(() => {
    if (!editor || !variant || inited) {
      return;
    }

    // let background = backgrounds.find(
    //     (x) => x.id == productState.backgroundId
    // );
    // if (background) {
    //     console.log(background);
    // }

    editor.canvas.setDimensions({
      width: 750,
      height: 350,
    });

    if (variant.imageFileName) {
      const checkTransparentImageUrl = `https://api.abccheck.webarysites.com/uploads/${variant.imageFileName}`;

      fabric.Image.fromURL(checkTransparentImageUrl, function (img) {
        editor.canvas.setBackgroundImage(
          img,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            scaleX: editor.canvas.width / img.width,
            scaleY: editor.canvas.height / img.height,
          }
        );
      });
    }

    if (!variant.placeholdersJson) {
      return;
    }
    const objectsArray = JSON.parse(variant.placeholdersJson);
    const objectsMap = {};
    objectsArray.forEach((item) => {
      objectsMap[item.text] = item;
    });
    fabric.util.enlivenObjects(
      objectsArray,
      /** @param {Array<fabric.Object>} objects */
      (objects) => {
        for (const o of objects) {
          // o.text = "";
          // o["lockMovementX"] = true;
          // o["lockMovementY"] = true;
          // o["lockUniScaling"] = true;
          // o["hasControls"] = false;
          //o["borderColor"] = "red";

          if (o.type === "text") {
            o.width = objectsMap[o.text]?.width || o.width;
            o.setControlVisible("mt", false);
            o.setControlVisible("mb", false);
            o.setControlVisible("mtr", false);
            o.setControlVisible("tl", false);
            o.setControlVisible("tr", false);
            o.setControlVisible("bl", false);
            o.setControlVisible("br", false);
            const dataItemIndex = data.findIndex(
              (item) => item.label === o.text
            );
            if (data[dataItemIndex]) {
              if (typeof o.isRequired !== "boolean") {
                o.isRequired = data[dataItemIndex].isRequired;
              }
              if (typeof o.isBold !== "boolean") {
                o.isBold = data[dataItemIndex].isBold;
              }
              data[dataItemIndex] = {
                ...data[dataItemIndex],
                textAlign: o.textAlign,
                isRequired: o.isRequired,
                fontSize: o.fontSize,
              };
              if (data[dataItemIndex].hasOwnProperty("isBold")) {
                data[dataItemIndex].isBold = o.isBold;
              }
            }
          }

          editor.canvas.add(o);
        }
        setData([...data]);

        editor.canvas.renderAll();
      }
    );
    editor.canvas.setDimensions({
      width: variant.width,
      height: variant.height,
    });

    setInited(true);
  }, [variant, editor]);

  useEffect(() => {
    editor?.canvas.setDimensions({
      width: 750,
      height: 350,
    });

    if (!editor?.canvas) {
      return;
    }
    /**
     * @param {fabric.Object} obj
     * @param {"top"|"left"} position
     */
    const addAligner = (obj, position) => {
      const points =
        position === "left"
          ? [obj.left, 0, obj.left, editor.canvas.height]
          : [0, obj.top, editor.canvas.width, obj.top];
      const line = new fabric.Line(points, {
        fill: `${position}Aligner`,
        stroke: "purple",
        hasControls: false,
        hasRotatingPoint: false,
        lockUniScaling: true,
        strokeWidth: 1,
        opacity: 0.5,
      });
      line.setControlVisible("tl", false);
      line.setControlVisible("mt", false);
      line.setControlVisible("tr", false);
      line.setControlVisible("ml", false);
      line.setControlVisible("mr", false);
      line.setControlVisible("bl", false);
      line.setControlVisible("mb", false);
      line.setControlVisible("br", false);
      line.setControlVisible("mtr", false);
      editor.canvas.add(line);
    };

    /** @param {import("fabric/fabric-impl").IEvent} options */
    function onChange(options) {
      options.target.setCoords();
      const lines = editor.canvas.getObjects("line");
      const aligner = lines.find((item) =>
        ["leftAligner", "topAligner"].includes(item.fill)
      );
      editor.canvas.remove(aligner);
      let hasAligner = false;
      editor.canvas.forEachObject(function (obj) {
        if (obj === options.target || hasAligner) return;
        ["left", "top"].forEach((position) => {
          if (Math.abs(options.target[position] - obj[position]) < 6) {
            hasAligner = true;
            options.target.set(position, obj[position]);
            addAligner(obj, position);
          }
        });
      });
    }

    editor.canvas.on({
      "object:moving": onChange,
      "object:scaling": (options) => {
        if (options.target.type !== "text") {
          return;
        }
        const newWidth = options.target.width * options.target.scaleX;
        options.target.set({ width: newWidth, scaleX: 1 });
      },
      "object:modified": () => {
        const lines = editor.canvas.getObjects("line");
        lines.forEach((line) => {
          if (["leftAligner", "topAligner"].includes(line.fill)) {
            editor.canvas.remove(line);
          }
        });
      },
    });
  }, [editor]);

  let wpProductId;

  wpProductId = query.get("WPProductId");

  const backFileSelected = (e) => {
    setBackFile(e.target.files[0]);
  };

  const fileSelected = (e) => {
    setFrontFile(e.target.files[0]);
    fabric.Image.fromURL(
      URL.createObjectURL(e.target.files[0]),
      function (img) {
        editor.canvas.setBackgroundImage(
          img,
          editor.canvas.renderAll.bind(editor.canvas),
          {
            scaleX: editor.canvas.width / img.width,
            scaleY: editor.canvas.height / img.height,
          }
        );
      }
    );
  };

  const variantFormChanged = (e) => {
    setVariant({
      ...variant,
      [e.target.name]: e.target.value,
    });

    if (e.target.name == "width") {
      editor.canvas.setDimensions({
        width: e.target.value,
        height: editor.canvas.getHeight(),
      });
    }
    if (e.target.name == "height") {
      editor.canvas.setDimensions({
        width: editor.canvas.getWidth(),
        height: e.target.value,
      });
    }
  };

  const inputFocused = (e) => {
    const id = e.target.name;
    const label = e.target.getAttribute("data-label");
    let existing = editor.canvas
      .getObjects()
      .find((x) => (x.fill && x.fill === id) || (x.text && x.text == label));

    if (!existing) {
      editor.addText(label);
      const dataItem = data.find((item) => item.id === id);
      existing = editor.canvas
        .getObjects()
        .find((x) => (x.fill && x.fill === id) || (x.text && x.text == label));
      existing.hasControls = true;
      existing.hasRotatingPoint = false;
      existing.editable = false;
      existing.isRequired = !!dataItem?.isRequired;
      if (dataItem.hasOwnProperty("isBold")) {
        existing.isBold = !!dataItem?.isBold;
      }
      existing.setControlVisible("mt", false);
      existing.setControlVisible("mb", false);
      existing.setControlVisible("mtr", false);
      existing.setControlVisible("tl", false);
      existing.setControlVisible("tr", false);
      existing.setControlVisible("bl", false);
      existing.setControlVisible("br", false);
    }
    existing.fill = id;

    editor.canvas.setActiveObject(existing);
    editor.canvas.renderAll();
  };

  const removeSelectedObject = () => {
    editor.canvas.remove(editor.canvas.getActiveObject());
  };

  /** @param {fabric.IPathOptions} options */
  const addLine = (options = {}) => {
    const line = new fabric.Path("M 160, 1, 1, 1", {
      fill: "",
      stroke: "black",
      hasControls: true,
      hasRotatingPoint: false,
      lockUniScaling: true,
      strokeWidth: 2,
      objectCaching: false,
      top: 100,
      left: 100,
      height: 100,
      angle: 0,
      ...options,
    });
    line.setControlVisible("bl", false);
    line.setControlVisible("br", false);
    line.setControlVisible("tl", false);
    line.setControlVisible("tr", false);
    line.setControlVisible("mt", false);
    line.setControlVisible("mb", false);
    line.setControlVisible("mtr", false);

    editor.canvas.add(line);
    editor.canvas.setActiveObject(line);
  };

  const addLineVertical = () => {
    addLine({ top: 180, angle: 270 });
  };

  const increaseLineWidth = () => {
    const obj = editor.canvas.getActiveObject();
    if (!obj) {
      return;
    }
    obj.strokeWidth = obj.strokeWidth + 1;
    editor.canvas.renderAll();
  };

  const decreaseLineWidth = () => {
    const obj = editor.canvas.getActiveObject();
    if (!obj) {
      return;
    }
    obj.strokeWidth = obj.strokeWidth - 1;
    editor.canvas.renderAll();
  };

  const addRect = () => {
    const rect = new fabric.Rect({
      left: 50,
      top: 50,
      width: 50,
      height: 50,
      fill: "rgba(0, 0, 0, 0)",
      stroke: "#000000",
      strokeUniform: true,
    });
    editor.canvas.add(rect);
    editor.canvas.renderAll();
  };

  const addExtraSignatureLine = () => {
    addLine({ top: 160, fill: "extraSignatureLine" });
  };

  const addSignatureLine = () => {
    addLine({ top: 160, fill: "signatureLine" });
  };

  const fontSizeChanged = (e) => {
    let valueToSearch = data.find((x) => x.id == e.target.name);
    if (!valueToSearch) {
      return;
    }

    const itemIndex = data.indexOf(valueToSearch);
    let newData = [...data];
    newData.splice(itemIndex, 1, {
      ...valueToSearch,
      fontSize: +e.target.value,
    });

    setData(newData);

    let label = valueToSearch.label;
    let object = editor.canvas
      .getObjects()
      .find((x) => x.text && x.text == label);
    if (object) {
      const width = object.width;
      object.fontSize = e.target.value;
      editor.canvas.renderAll();
      if (object.width !== width) {
        object.width = width;
        editor.canvas.renderAll();
      }
    }
  };

  const isBoldChanged = (e) => {
    let valueToSearch = data.find((x) => x.id == e.target.name);
    if (!valueToSearch) {
      return;
    }

    const itemIndex = data.indexOf(valueToSearch);
    let newData = [...data];
    newData.splice(itemIndex, 1, {
      ...valueToSearch,
      isBold: !valueToSearch.isBold,
    });

    setData(newData);

    let label = valueToSearch.label;
    let object = editor.canvas
      .getObjects()
      .find((x) => x.text && x.text == label);
    if (object) {
      const width = object.width;
      object.isBold = !valueToSearch.isBold;
      if (!valueToSearch.isBold) {
        object.fontWeight = "bold";
      } else {
        object.fontWeight = "normal";
        object.fontStyle = "normal";
      }

      editor.canvas.renderAll();
      if (object.width !== width) {
        object.width = width;
        editor.canvas.renderAll();
      }
    }
  };

  const isRequiredChanged = (e) => {
    const itemIndex = data.findIndex((i) => i.id == e.target.name);
    if (itemIndex === -1) {
      return;
    }
    const textObject = editor.canvas
      .getObjects("text")
      .find(
        (x) =>
          (x.fill && x.fill === data[itemIndex].id) ||
          (x.text && x.text == data[itemIndex].label)
      );
    textObject.isRequired = e.target.checked;
    data[itemIndex] = {
      ...data[itemIndex],
      isRequired: e.target.checked,
    };

    setData([...data]);
  };

  const dataValueChange = (e) => {
    const id = e.target.name;
    const newText = e.target.value;
    if (showActualValues) {
      const label = e.target.getAttribute("data-label");
      const obj = editor.canvas
        .getObjects()
        .find((x) => (x.fill && x.fill === id) || (x.text && x.text == label));
      obj.text = newText;
    }
    const itemIndex = data.findIndex((i) => i.id == id);
    data[itemIndex] = {
      ...data[itemIndex],
      value: newText,
    };
    setData([...data]);
  };

  const handleShowActualValuesChange = (checked) => {
    setShowActualValues(checked);
    /** @type {Array<fabric.Text>} */
    const textObjects = editor.canvas.getObjects("text");
    const objectsOriginalWidth = [];
    textObjects.forEach((item, index) => {
      const dataItem = data.find(
        (d) =>
          (item.fill && item.fill === d.id) ||
          (item.text && item.text == d.label)
      );
      item.text = (checked ? dataItem?.value : dataItem?.label) || item.text;
      objectsOriginalWidth[index] = item.width;
    });
    editor.canvas.renderAll();
    if (
      textObjects.some(
        (item, index) => objectsOriginalWidth[index] !== item.width
      )
    ) {
      for (const index in textObjects) {
        textObjects[index].width = objectsOriginalWidth[index];
      }
      editor.canvas.renderAll();
    }
  };

  const fontFamilyChanged = (e) => {
    let objects = editor.canvas.getObjects();
    for (let object of objects) {
      object.fontFamily = e.target.value;
    }

    editor.canvas.renderAll();
  };

  const to = (where, id) => {
    const index = data.findIndex((x) => x.id == id);
    const item = data[index];
    if (!item) {
      return;
    }

    const label = item.label;
    const object = editor.canvas
      .getObjects()
      .find((x) => (x.fill && x.fill === id) || (x.text && x.text == label));

    if (!object) {
      return;
    }
    const oldWidth = object.width;
    object.textAlign = where;
    data[index] = { ...item, textAlign: where };
    setData([...data]);
    const objJson = object.toJSON();
    editor.canvas.remove(object);

    fabric.util.enlivenObjects([objJson], (objects) => {
      objects[0].width = oldWidth;
      objects[0].setControlVisible("mt", false);
      objects[0].setControlVisible("mb", false);
      objects[0].setControlVisible("mtr", false);
      objects[0].setControlVisible("tl", false);
      objects[0].setControlVisible("tr", false);
      objects[0].setControlVisible("bl", false);
      objects[0].setControlVisible("br", false);
      editor.canvas.add(objects[0]);
      editor.canvas.renderAll();
    });
  };

  const saveVariant = async () => {
    if (!isTemplate && !wpProductId && !variant.wpProductId) {
      setMessageType(2);
      setMessage('Can\'t save variant without "wpProductId"!')
      setTimeout(() => { setMessageType(0); setMessage(''); }, 5000)
      //notify.error('Can\'t save variant without "wpProductId"!');
      return;
    }
    // if (isTemplate && (!variant.name || !variant.description)) {
    //   notify.error('Template "name" and "description" are required!');
    //   return;
    // }
    handleShowActualValuesChange(false);
    let json = JSON.stringify(
      editor.canvas.toJSON(["isRequired", "isBold"]).objects
    );
    setVariant({
      ...variant,
      width: editor.canvas.getWidth(),
      height: editor.canvas.getHeight(),
    });

    let body = {
      ...variant,
      id: 0,
      backgrounds: JSON.stringify(variant.backgrounds),
      placeholdersJson: json,
    };
    form = new FormData();
    for (let prop of Object.keys(body)) {
      form.append(prop, body[prop] ?? null);
    }

    if (variantId && !body.id && !fromTemplate) {
      form.set("id", variantId);
      console.log(fromTemplate, "HERE");
    }

    if (!variantId && wpProductId) {
      console.log(variant);
      form.set("id", variant.id);
    }

    form.set("file", frontFile);
    form.set("backFile", backFile);

    form.set("productId", 1);
    if (!body.wpProductId) {
      form.set("wpProductId", wpProductId);
    }

    console.log([...form.entries()])

    const response = await fetch(
      `https://api.abccheck.webarysites.com/api/${
        isTemplate ? "TemplateVariant/CreateOrUpdateTemplate" : "ProductVarient"
      }`,
      {
        method: "POST",
        body: form,
      }
    );

    if (response.ok) {
      setMessageType(1);
      setMessage("Variant saved!");
      setTimeout(() => { setMessageType(0); setMessage(''); }, 5000)
      //notify.success("Variant saved!");
    } else {
      setMessageType(2);
      setMessage("Something went wrong!");
      setTimeout(() => { setMessageType(0); setMessage(''); }, 5000)

      //notify.error("Something went wrong!");
    }
  };

  const handleImageUploadToCanvas = async (event) => {
    const file = event.target.files[0];
    const base64 = await toBase64(file);
    if (!base64) {
      return;
    }
    addImage(base64, editor.canvas);
  };

  const handleBackgroundChecked = (checked, id) => {
    const backgrounds = variant?.backgrounds || [];
    if (checked) {
      backgrounds.push(id);
    } else {
      const index = backgrounds.findIndex((item) => item === id);
      backgrounds.splice(index, 1);
    }
    setVariant({ ...variant, backgrounds });
  };

  const handleTemplateSelect = (value) => {
    const id = Number(value);
    if (!id) {
      return;
    }
    const template = { ...templates.find((item) => item.id === id) };
    if (template.backgrounds && typeof template.backgrounds === "string") {
      template.backgrounds = JSON.parse(template.backgrounds);
    }
    setInited(false);
    editor.canvas.clear();
    setVariant({ ...template });
    setFromTemplate(true);
  };

  return (
    <StickyContainer>
      <ImagePreview
        show={!!previewImage}
        src={previewImage}
        onClose={() => setPreviewImage("")}
        alt="image"
        width="100%"
      />
      <WithLoading loading={loading} fluid className="p-0">
        <Sticky>
          {({ style }) => (
            <div style={{ ...style, zIndex: 2 }} className="bg-body">
              <h1 className="h3 mb-3">
                Product {isTemplate ? "Template" : "Variant"}
              </h1>
              <Row>
                <Col md="3">
                  <Form>
                    <Form.Group className="mb-1">
                      <Form.Label>Front file</Form.Label>
                      <InputGroup>
                        <Form.Control
                          type="file"
                          name="file"
                          onChange={fileSelected}
                        />
                        {variant?.imageFileName && (
                          <Button
                            variant="outline"
                            type="button"
                            onClick={() =>
                              setPreviewImage(
                                `https://api.abccheck.webarysites.com/uploads/${variant.imageFileName}`
                              )
                            }
                          >
                            <Eye size={20} />
                          </Button>
                        )}
                      </InputGroup>
                    </Form.Group>
                    <Form.Group className="mb-1">
                      <Form.Label>Back File</Form.Label>
                      <InputGroup>
                        <Form.Control type="file" onChange={backFileSelected} />
                        {variant?.backImageFileName && (
                          <Button
                            variant="outline"
                            type="button"
                            onClick={() =>
                              setPreviewImage(
                                `https://api.abccheck.webarysites.com/uploads/${variant.backImageFileName}`
                              )
                            }
                          >
                            <Eye size={20} />
                          </Button>
                        )}
                      </InputGroup>
                    </Form.Group>
                    {isTemplate ? (
                      <>
                        <Form.Group className="mb-1">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            onChange={variantFormChanged}
                            value={variant?.name || ""}
                          />
                        </Form.Group>
                        <Form.Group className="mb-1">
                          <Form.Label>Description</Form.Label>
                          <Form.Control
                            as="textarea"
                            style={{ minHeight: 72 }}
                            type="text"
                            name="description"
                            onChange={variantFormChanged}
                            value={variant?.description || ""}
                          />
                        </Form.Group>
                      </>
                    ) : (
                      <Form.Group className="mb-1">
                        <Form.Label>Template</Form.Label>
                        <Form.Select
                          name="template"
                          onChange={(e) =>
                            handleTemplateSelect(e.currentTarget.value)
                          }
                        >
                          <option>Select a template</option>
                          {templates.map((template) => (
                            <option key={template.id} value={template.id}>
                              {template.name}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    )}
                    <Form.Check
                      id="show-actual-values"
                      type="switch"
                      className="form-switch-md mt-2"
                      name="showActualValues"
                      label="Show Actual Values"
                      onChange={(e) =>
                        handleShowActualValuesChange(e.target.checked)
                      }
                      checked={showActualValues}
                    />
                    {/* <Form.Group className="mb-1">
                                <Form.Label>Width</Form.Label>
                                <Form.Control type="number" name="width" onChange={variantFormChanged} value={variant.width} />
                            </Form.Group>

                            <Form.Group className="mb-1">
                                <Form.Label>Height</Form.Label>
                                <Form.Control type="number" name="height" onChange={variantFormChanged} value={variant.height} />
                            </Form.Group>

                            <Form.Group className="mb-1">
                                <Form.Label>Number of checks</Form.Label>
                                <Form.Control type="number" name="numberOfChecks" onChange={variantFormChanged} value={variant.numberOfChecks} />
                            </Form.Group> */}
                  </Form>
                </Col>

                <Col
                  md={9}
                  className="d-flex justify-content-center"
                  style={{ backgroundColor: "#BBBBBB" }}
                >
                  <FabricJSCanvas
                    className="canvas mt-3 mb-3"
                    onReady={onReady}
                  />
                </Col>
              </Row>
              <br />
            </div>
          )}
        </Sticky>
        <Row>
          {data.map((d, index) => (
            <Col md="3" key={index}>
              <Form.Group className="mb-1">
                <Form.Label>{d.label}</Form.Label>
                <Form.Control
                  type="text"
                  onFocus={inputFocused}
                  onChange={dataValueChange}
                  data-label={d.label}
                  name={d.id}
                  value={d.value}
                />
              </Form.Group>

              <Form.Group as={Row} className="mb-1 gx-3">
                <Col sm={3}>
                  <Form.Label className="text-sm-right">Font Size</Form.Label>
                  <Form.Control
                    type="number"
                    name={d.id}
                    onChange={fontSizeChanged}
                    value={d.fontSize}
                  />
                </Col>
                <Col sm={3}>
                  {d.hasOwnProperty("isBold") && (
                    <Form.Check
                      id={`${d.id}-is-bold`}
                      label="Is Bold"
                      type="checkbox"
                      name={d.id}
                      onChange={isBoldChanged}
                      checked={d.isBold}
                    />
                  )}
                </Col>
                <Col sm={6}>
                  <ButtonGroup className="position-static mt-1">
                    {alignments.map((item) => (
                      <Button
                        key={item.value}
                        variant={`${d.textAlign !== item.value ? "outline-" : ""
                          }primary`}
                        onClick={() => to(item.value, d.id)}
                        className="position-static"
                      >
                        <item.Icon size={20} />
                      </Button>
                    ))}
                  </ButtonGroup>
                  <Form.Check
                    id={`${d.id}-is-required`}
                    type="checkbox"
                    name={d.id}
                    label="Is Required"
                    className="mt-1"
                    onChange={isRequiredChanged}
                    checked={d.isRequired}
                  />
                </Col>
              </Form.Group>
            </Col>
          ))}
        </Row>

        <hr />

        <Row>
          <Col md={3}>
            <Form.Group as={Row} className="mb-3">
              {/* <Form.Label column sm={4} className="text-sm-right">
                                Font Size
                            </Form.Label> */}
              <Form.Select className="m-2" onChange={fontFamilyChanged}>
                <option>Arial</option>
                <option>Verdana</option>
                <option>Consolas</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={9}>
            <Button
              variant="danger"
              className="m-2"
              onClick={removeSelectedObject}
            >
              Remove selected
            </Button>
            <Button className="m-2" variant="primary" onClick={() => addLine()}>
              Add Line (horizontal)
            </Button>
            <Button className="m-2" variant="primary" onClick={addLineVertical}>
              Add Line (vertical)
            </Button>
            <Button
              className="m-2"
              variant="primary"
              onClick={addSignatureLine}
            >
              Add signature line
            </Button>
            <Button
              className="m-2"
              variant="primary"
              onClick={addExtraSignatureLine}
            >
              Add Extra signature line
            </Button>
            <Button className="m-2" variant="primary" onClick={addRect}>
              Add Rectangle
            </Button>
            <Button className="m-2" onClick={increaseLineWidth}>
              Increase width
            </Button>
            <Button className="m-2" onClick={decreaseLineWidth}>
              Decrease width
            </Button>
            <Form.Control
              type="file"
              accept="image/*"
              className="w-auto m-2"
              onChange={handleImageUploadToCanvas}
            />
          </Col>
        </Row>
        <hr />
        <div className="d-flex">
          {backgrounds.map((background) => (
            <div
              key={background.id}
              className="d-flex flex-column mx-1 p-1 border rounded"
            >
              <img
                width={160}
                src={`https://api.abccheck.webarysites.com/uploads/${background.filePath}`}
                onClick={() =>
                  handleBackgroundChecked(
                    !variant?.backgrounds?.includes(background.id),
                    background.id
                  )
                }
              />
              <Form.Check
                id={background.id}
                value={background.id}
                label={background.name}
                checked={variant?.backgrounds?.includes(background.id) || false}
                onChange={(e) =>
                  handleBackgroundChecked(e.target.checked, background.id)
                }
                className="d-flex justify-content-between mx-2"
              />
            </div>
          ))}
        </div>
        <hr />
        <Row className="mt-1">
          <Col md={1}>
            <Button type="submit" variant="primary" onClick={saveVariant}>
              Save
            </Button>

          </Col>
          <Col md={6}>
            {messageType != 0 && (<Alert className="" variant={messageType == 1 ? "success" : "danger"}>
              <p className="ms-2 text-center align-center">{message}</p>
            </Alert>)}
          </Col>
        </Row>
      </WithLoading>
    </StickyContainer>
  );
};

export default ProductVariant;
